body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
$bg: #f2f2f2;
$black: #000000;
$text: $black;
$light-grey: #d9d9d9;
$grey: #bfbfbf;
$dark-grey: #8c8c8c;
$grey-border: $black;
$grey-shadow: #919191;
/* Color Theme Swatches in Hex */
$paint-splatter-idea-1: #bf3064;
$paint-splatter-idea-2: #a545bf;
$paint-splatter-idea-3: #025373;
$paint-splatter-idea-4: #f29d35;
$paint-splatter-idea-5: #f2784b;

.main-heading {
  h1 {
    background-color: black;
    line-height: 1;
    margin: 0rem;
    text-align: left;
    font-style: italic;
    font-size: 13vw;
    color: $bg;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -0.3rem;
    @media all and (min-width: 800px) {
      letter-spacing: -0.6rem;
    }
  }
}

.main-display {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media all and(min-width: 700px) {
    flex-direction: row;
  }
  h2 {
    font-style: italic;
    font-size: 2rem;
    background-color: black;
    color: $bg;
    padding: 1rem 1.5rem;
    text-transform: uppercase;
  }
  &-right {
    @media all and(max-width: 700px) {
      margin: auto;
    }
  }
  &-left {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 3rem;
    @media all and(min-width: 700px) {
      //width: 75vw;
    }
    h2 {
      font-size: 1.5rem;
    }
    &-options {
      font-size: 1.25rem;
      line-height: 1.25;
    }
    &-controls {
      line-height: 1.5;
      // Button 3D function adapted from Kathy Kato, so, so rad https://codepen.io/kathykato/pen/gOOjgmP
      &-options {
        text-align: center;
        &-inner {
          display: inline-block;
          text-align: left;
        }
      }
      &-button {
        text-align: center;
      }
      &-small-ones {
        padding: 1rem 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        @media all and(min-width: 600px) {
          justify-content: space-between;
        }
      }
    }
  }
  &-right {
    text-align: left;
    h2 {
      text-align: center;
    }
  }
}

svg {
  position: absolute;
  z-index: -1;
}

button.generate-card {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  background: $light-grey;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  margin-bottom: 5rem;
  font-size: 2.5rem;
  @media all and(min-width: 500px) {
    font-size: 3rem;
  }
  font-weight: 900;
  color: $text;
  text-transform: uppercase;
  padding: 1em 1.5em;
  background: &light-grey;
  border: 5px solid $grey-border;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    background 150ms cubic-bezier(0, 0, 0.58, 1);
  @media all and(min-width: 500px) {
    padding: 1.25em 2em;
  }
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $dark-grey;
    border-radius: inherit;
    box-shadow: 0 0 0 5px $grey-border, 0 0.625em 0 5px $grey-shadow;
    transform: translate3d(0, 0.75em, -1em);
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
  }
  &:hover {
    background: $grey;
    transform: translate(0, 0.25em);
    &::before {
      box-shadow: 0 0 0 5px $grey-border, 0 0.5em 0 5px $grey-shadow;
      transform: translate3d(0, 0.5em, -1em);
    }
  }
  //this fixes problem with hover on mobile devices, by apply hover style constantly
  @media all and(max-width: 415px) {
    background: $grey;
    transform: translate(0, 0.25em);
    &::before {
      box-shadow: 0 0 0 5px $grey-border, 0 0.5em 0 5px $grey-shadow;
      transform: translate3d(0, 0.5em, -1em);
    }
  }
  &:active {
    background: $grey;
    transform: translate(0em, 0.75em);
    &::before {
      box-shadow: 0 0 0 5px $grey-border, 0 0 $grey-shadow;
      transform: translate3d(0, 0, -1em);
    }
  }
}
.main-display-right-controls {
  text-align: center;
}
.main-display-right-controls button,
.help-button button,
.save-button button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  margin-bottom: 4rem;
  font-size: 1.5rem;
  &.sort-cards,
  &.help,
  &.save {
    font-weight: 900;
    color: $text;
    text-transform: uppercase;
    padding: 1em 1em;
    background: &light-grey;
    border: 5px solid $grey-border;
    border-top-left-radius: 1em;
    border-bottom-right-radius: 1em;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      background 150ms cubic-bezier(0, 0, 0.58, 1);
    &::before {
      position: absolute;
      opacity: 0.85;
      display: inline;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $dark-grey;
      border-radius: inherit;
      box-shadow: 0 0 0 5px $grey-border, 0 0.625em 0 5px $grey-shadow;
      transform: translate3d(0, 0.75em, -1em);
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
        box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
    }
    &:hover {
      background: $grey;
      transform: translate(0, 0.25em);
      &::before {
        box-shadow: 0 0 0 5px $grey-border, 0 0.5em 0 5px $grey-shadow;
        transform: translate3d(0, 0.5em, -1em);
      }
    }
    //this fixes problem with hover on mobile devices, by apply hover style constantly
    @media all and(max-width: 415px) {
      background: $grey;
      transform: translate(0, 0.25em);
      &::before {
        box-shadow: 0 0 0 5px $grey-border, 0 0.5em 0 5px $grey-shadow;
        transform: translate3d(0, 0.5em, -1em);
      }
    }
    &:active {
      background: $grey;
      transform: translate(0em, 0.75em);
      &::before {
        box-shadow: 0 0 0 5px $grey-border, 0 0 $grey-shadow;
        transform: translate3d(0, 0, -1em);
      }
    }
  }
}
.card {
  margin-bottom: 5rem;
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .card-frame {
    text-align: left;
  }
  .card-graphics {
    text-align: center;
    svg polygon {
      stroke-width: 6px;
    }
  }
  .card-info {
    display: flex;
    padding-top: 1rem;
    flex-direction: column;
    &-name {
      font-family: Rowdy, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      padding-left: 1rem;
      font-size: 1.75rem;
      text-align: center;
    }
    .filled-star,
    .empty-star {
      width: 1.2rem;
      height: 1.2rem;
      stroke-width: 5px;
      margin-right: 0.05rem;
      fill: #bf1120;
    }
    .stars {
      text-align: center;
      //margin: auto;
      svg {
        position: static;
      }
    }
    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .export-card {
    font-size: 1.25rem;
    transition: color 150ms cubic-bezier(0, 0, 0.58, 1);
    button {
      margin-left: 0.75rem;
      margin-top: 0rem;
    }
    svg {
      z-index: 0;
      position: relative;
      transition: color 150ms cubic-bezier(0, 0, 0.58, 1);
      //padding-left: 1.25rem;
      //padding-top: 0.25rem;
      &:hover {
        color: $black;
      }
    }
  }
  .export-card-button-menu {
    color: $black;
    font-size: 1.5rem;
    opacity: 0.8;
    top: -40px;
    left: -8px;
    position: absolute;
    background-color: $bg;
    padding: 0.25rem 0.5rem;
    border: 5px solid #000;
    border-radius: 1rem 0 1rem 0;
    margin-bottom: 100px;
    transition: color display 150ms linear;
    animation: open-export-menu 0.5s 1;
    a {
      color: $black;
    }
    svg {
      padding-top: 3rem;
      z-index: 1;
      padding: 0.15rem !important;
      position: relative;
    }
    &-tweet {
      padding-left: 0.15rem;
    }
    &-facebook {
      margin-left: -1rem;
      svg {
        font-size: 1.5rem;
      }
    }
  }
  .arrow-down {
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    bottom: -15px;
    left: 25px;
    border-top: 10px solid $black;
  }
}
.card.latest {
  height: 200px;
  .card-info {
    padding-top: 2.5rem;
    @media all and (min-width: 500px) {
      padding-top: 1.5rem;
    }
  }
  span {
    font-size: 2rem;
    @media all and (min-width: 500px) {
      font-size: 3rem;
    }
  }
  .filled-star,
  .empty-star {
    width: 2rem !important;
    height: 2rem !important;
  }
  .card-frame svg {
    margin: 0rem;
    @media all and (max-width: 500px) {
      margin: 1.5rem 0 0 3rem;
      width: 330px !important;
      height: 185px !important;
    }
  }
  .delete-card {
    button {
      font-size: 2rem;
      padding: 1rem 3rem 0 0;
      @media all and (min-width: 500px) {
        padding: 0;
      }
    }
  }
  .export-card {
    button {
      margin-left: 2.25rem;
      margin-top: 1rem;
    }
    svg {
      //margin: 0.75rem 0rem 0rem 3rem;
      font-size: 2.25rem;
    }
  }
  .export-card-button-menu {
    color: $black;
    font-size: 2.25rem;
    opacity: 0.8;
    top: -15px;
    left: 20px;
    position: absolute;
    background-color: $bg;
    padding: 0.25rem 0.5rem;
    padding-top: 0.75rem;
    border: 5px solid #000;
    border-radius: 1rem 0 1rem 0;
    margin-bottom: 100px;
    transition: color display 150ms linear;
    animation: open-export-menu 0.5s 1;
    svg {
      z-index: 1;
      padding: 0.15rem !important;
      position: relative;
    }
    &-facebook {
      margin-left: -2.3rem;
      margin-top: -1rem;
      svg {
        font-size: 2.15rem;
      }
    }
  }
  .arrow-down {
    bottom: -15px;
    left: 29px;
  }
}

.save-button {
  text-align: right;
  transform: translateY(-4rem);
}

@keyframes open-export-menu {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

.card-info,
.card-graphics {
  position: relative;
}

.delete-card,
.export-card {
  text-align: right;
  button {
    border: 0;
    background-color: inherit;
    font-size: 1.5rem;
    font-weight: 900;
  }
}

.color-switch {
  margin-top: 1rem;
  circle {
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1);
  }
  //floats and shrinks the paint color controls
  position: fixed;
  bottom: 110px;
  right: 170px;
  svg {
    transform: scale(0.7);
    opacity: 0.9;
    padding-top: 1rem;
    background: #fff;
    border: 3px solid #fff;
    border-radius: 10px;
    z-index: 1;
  }
  .rainbow-square {
    transform: scale(1);
  }
}

.help-button {
  transform: scale(0.5);
  position: fixed;
  bottom: -23px;
  left: 0px;
}

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0.95;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -45vh;
  margin-left: -45vw;
  height: 80vh;
  width: 80vw;
  background: #fff;
  border: 5px solid #000;
  border-radius: 2em 0 2em 0;
  box-shadow: 0px 10px 0 $grey-shadow;
  z-index: 1;
  padding: 1rem 1rem;
  font-size: 1rem;
  h1 {
    margin-top: 0;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    font-size: 2rem;
  }
  h2 {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
  p {
    margin-top: 0.5rem;
  }
  .empty-star {
    width: 0.9rem;
    height: 0.9rem;
    stroke-width: 5px;
    margin-right: 0.05rem;
    fill: #bf1120;
  }
  // doesn't fit well on landscape mobile screens

  @media all and (min-width: 550px) {
    padding: 1rem 2rem;

    font-size: 1.5rem;
    h1 {
      margin-top: 0;
      font-size: 3rem;
    }
    h2 {
      font-size: 2rem;
    }
    .empty-star {
      width: 1.2rem;
      height: 1.2rem;
      stroke-width: 5px;
      margin-right: 0.05rem;
      fill: #bf1120;
    }
  }
  .stars {
    display: inline;
    text-align: center;
    //margin: auto;
    svg {
      position: static;
    }
  }
  .close {
    text-align: right;
    button {
      border: 0;
      background-color: inherit;
      font-size: 3rem;
      font-weight: 900;
    }
    @media all and (max-device-width: 640px) {
      margin-right: -3rem;
    }
  }
  &-button {
    text-align: right;
    button {
      font-size: 3rem;
      background: none;
      border: 0;
    }
    @media all and (max-device-width: 640px) {
      margin-right: -3rem;
    }
  }
}

.card.export {
  margin: 10rem;
  .card-info {
    padding-top: 8.5rem;
    &-name {
      font-size: 5rem;
    }
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid black;
}

.welcome-page-icon {
  position: relative;
}
